import { makeStyles } from "@mui/styles";

const DiaryStyles = makeStyles((theme) => ({
  main: {
    position: "relative",
    width: "100%",
    // height: "100vh",
    // background: "yellow",
    // padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      // heightMin: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      // heightMin: "100%",
    },
  },

  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  },
  mainCard: {
    // width: "100%",
    // height: "100vh",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      // height: "50vh",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      // height: "50vh",
    },
  },

  mainContent: {
    // width: "100%",
    // height: "100vh",
    position: "relative",
    display: "flex",
    backgroundColor: "red",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      // heightMin: "100vh",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      // heightMin: "100vh",
    },

    // background: "red",
  },
  mainParticlesLeft: {
    width: "100%",
    // height: "100%",
    display: "flex",
    // position: "absolute",
    justifyContent: "space-between",
    marginBottom: "1rem",
    // backgroundColor: "yellow",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      marginTop: "2rem !important",
      marginBottom: "1rem",

      // height: "100vh",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: "2rem !important",
      marginBottom: "1rem",

      // height: "100vh",
    },
  },

  //create App style code//
  mainContainerDiaryStyle: {
    margin: "auto",
    display: 'flex',
    justifyContent: 'center',
    paddingTop: "3rem !important",
    paddingLeft: "0.1rem !important",
    paddingRight: "0.1rem !important",
    paddingBottom: "3rem !important",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      paddingTop: "6rem !important",
      paddingLeft: "1.2rem !important",
      paddingRight: "0.5rem !important",
    },
  },
  //Create App blog Style end code//

  cardRes: {
    width: "100%",
    height: "72vh",
    borderRadius: "2rem",
    // marginRight: "2rem",
    // marginBottom: "2rem",
    backgroundColor: "#77D4E3",
    fontFamily: "Museo700",
    padding: "2rem",
    // backgroundColor: "red",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "100%",
      height: "65vh",
      marginBottom: "2rem",
      marginTop: "2rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
      height: "65vh",
      marginTop: "2rem !important",

      // height: "20%",
    },
  },

  cardResImg: {
    // display: "flex",
    position: "absolute",
    top: "40%",
    left: "23%",
    transform: "translate(50%, -59%)",
    [theme.breakpoints.down("md")]: {
      // position: "absolute",
      // height: "40%",
      // width: "45%",
      top: "40%",
      left: "43%",
      transform: "translate(50%, -59%)",

      // top: "50%",
      // left: "50%",
      // transform: "translate(-50%, -50%)",
    },
    [theme.breakpoints.down("sm")]: {
      // position: "absolute",
      // position: "fixed",
      // height: "50%",
      // width: "65%",
      // top: "40%",
      // left: "9%",
      // transform: "translate(50%, -25%)",
      top: "40%",
      left: "10%",
      transform: "translate(64%, -69%)",
    },
  },

  createFabStyles3: {
    position: 'fixed', zIndex: '9999', top: '50%', right: '60px',
    '& .MuiFab-primary': {
      color: '#fff',
      backgroundColor: '#ffc107',
    }
  },

  moodStyling: {
    height: '5.4vw',
    width: '5.4vw',
    marginTop: '1.4rem',
    cursor: 'pointer !important',
    [theme.breakpoints.down("sm")]: {
      height: '12vh',
      width: '23vw',
    },
    [theme.breakpoints.down("md")]: {
      height: '12vh',
      width: '23vw',
    },
  },
  sliderStyle: {
    color: "#ffff !important",
    width: "10px !important",
    height: "auto",
    marginTop: "30px",
    '& input[type="range"]': {
      WebkitAppearance: "slider-vertical",
    },
    [theme.breakpoints.down("sm")]: {
      height: '52vh !important',
      marginTop: "32px",
    }
  },

  radioCustomBtn: {
    display: "block",
    maxWidth: "40%",
    justifyContent: "center",
    margin: "auto",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column-reverse",
    padding: "10px 6px",
    marginBottom: "2px",
    // backgroundColor: add ? "white" : "none",
    // borderRadius: add ? "40px" : "none",
    [theme.breakpoints.down("sm")]: {
      // fontSize: '1rem',
      //  display: "block",
      maxWidth: "100%",
      // justifyContent: "center",
      // margin: "auto",
      // textAlign: "center",
      // alignItems: "center",
      // flexDirection: "column-reverse",
      // padding: "10px 6px",
      // marginBottom: "2px",
      // backgroundColor: add ? "white" : "none",
      // borderRadius: add ? "40px" : "none",
    }
  },

  calenderStyles: {
    fontSize: '1.5rem',
    [theme.breakpoints.down("sm")]: {
      fontSize: '1rem',
    }
  },

  mainParticlesRight: {
    width: "24%",
    height: "100%",
    position: "absolute",
    right: 0,
    background: "#FFDB00",
    marginLeft: "2rem",
    borderRadius: "2rem",
    padding: "1rem",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    // backgroundColor: "pink",
  },
  mainCardUp: {
    width: "100%",
    // height: "67%",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      // width: "100%",
      // height: "100%",
      marginTop: "1rem",
    },
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      // width: "100%",
      // height: "100%",

      marginTop: "-3.5rem",
      // marginBottom: "1.5rem",
    },

    // background: "green",
    // marginButton: "1rem",
  },
  dateOfmonthStyle: {
    fontSize: '25px',
    textAlign: "center",
    fontFamily: "Museo700",
    color: "#0B2F64",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      textAlign: 'left',
      paddingTop: '5px',
    }

  },

  mainCardDown: {
    width: "64vw",
    height: "33vh",
    // position: "absolute",
    bottom: 0,
    marginTop: "1.5rem",
    background: "#FFB5C9",
    borderRadius: "1.5rem",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      marginBottom: "2rem",
      // width: "100%",
      // height: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      // padding: "1.5rem",
      marginBottom: "2rem",

      width: "90vw",
      height: "45vh",
    },
  },

  mainCardUpResponsive: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "100%",
      // height: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
      // height: "30%",
    },
  },

  mainContentDownLeft: {
    width: "100%",
    // height: "70vh",
    height: "50vh",
    background: "#CEBFFB",
    // marginTop: "1rem",
    overflow: "auto",
    borderRadius: "1.5rem",
    padding: theme.spacing(1),
    // "@global": {
    "*::-webkit-scrollbar": {
      width: "1rem",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid red",
    },
    // },
    // [theme.breakpoints.down("lg")]: {
    //   width: "50%",
    // },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",

      // width: "100%",
      height: "48vh",

      // marginTop: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      // width: "100%",
      height: "65vh",
      // padding: "20px",
      marginTop: "1rem",
      // backgroundColor: "red",
    },
  },

  mainContentDownRight: {
    // width: "100%",
    position: "relative",
    // height: "70vh",
    height: "50vh",
    background: "#77D4E3",
    // marginLeft: "2rem",
    overflow: "auto",
    borderRadius: "1.5rem",
    padding: "20px",
    [theme.breakpoints.down("md")]: {
      marginTop: "1.5rem",
      // width: "100%",
      height: "48vh",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: "2rem",
      // marginLeft: "0rem",

      // width: "100%",
      height: "48vh",

      // backgroundColor: "red",
    },
  },
  DownLeftMini: {
    // height: "4rem",
    width: "100%",
    backgroundColor: "#eae5f9",
    marginBottom: "1rem",
    borderRadius: "10px",
    overflow: "auto",
    // margin: "10px",
    padding: theme.spacing(1),
    textAline: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      // flexDirection: "column",
      width: "70vw",
      // backgroundColor: "red",
    },
  },
  DownRightMini: {
    // height: "4rem",
    width: "100%",
    backgroundColor: "#eae5f9",
    borderRadius: "10px",
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      // backgroundColor: "red",
      // height: "100%",
    },
  },
  dot: {
    height: "25px",
    width: "25px",
    backgroundColor: "#a093c7",
    borderRadius: "50%",
    display: "inline-block",
    justifyItems: "center",
    textAlign: "center",
    margin: "5px",
    fontSize: "14px",
    padding: "1px 1px",
    justifyContent: "center",
    // "&.active, &:hover": {
    //   backgroundColor: "#ffff",
    //   color: "#a093c7",
    // },

    "&.active": {
      backgroundColor: "#ffff",
      color: "#a093c7",
    },
  },

  //TO DO Style //

  DiaryAddImg: {
    borderRadius: '10px',
    width: "-webkit-fill-available",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  progStyle: {
    padding: "1rem",
    marginTop: '-2rem !important',
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      marginTop: "1rem !important",
    },
  },

  toDostyle: {
    borderRadius: "1rem",
    backgroundColor: "#FFDB00",
    height: "52vw",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "1rem",
      backgroundColor: "#FFDB00",
      height: "70vh",
    },
  },

  //////////////////////////////Diary Editor Styles

  fileicons: {
    height: "50px",
    width: "26px",
    margin: "auto",
    textAlign: "center !important",
    justifyContent: "center",
    display: "flex",
    padding: "1px",
  },

  // Textimg: {
  //   height: '30px',
  //   width: '20px',
  //   margin: 'auto',
  //   textAlign: 'center',
  //   justifyContent: 'center',
  //   display: 'flex',
  //   padding:'1px',
  // },
  iconname: {
    color: "#1E3B72",
    fontFamily: "Museo300",
    fontSize: "18px",
    display: "flex",
    justifyContent: "center",
    textallign: "center",
    margin: "auto",
    padding: "5px",
    paddingBottom: "22px",
  },
  ////////////////////////diary editor
  boxicon: {
    margin: "auto",
    height: "50px",
    color: "white",
    width: "50px",
    borderRadius: "26px",
    background: "#cbd0de",
    "&:hover": {
      background: "#1E3A71",
    },

    // number :{
    //   position: "absolute",
    //   font-size: "82.5%",
    //   lineHeight: "1",
    //   top: "0.75em",
    //   right: "0.75em",
    // }
  },
  //////////////////////////diary editor footer styles

  footer: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "end",
    textAlign: "center",
    backgroundColor: "#ffff",
    bottom: "10px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "30px 52px",
    },
  },

  ///dialog style//

  dailogstyle: {
    "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
      borderRadius: "1rem !important",
      height: "80vh",
      width: "50vw",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "1rem !important",
        height: "80vh",
        width: "80vw",
      },
    },
  },

  ////////////////calender

  "& calendar body cell": {
    position: " relative",
    height: "4em",
    borderRight: "1px solid var(--border-color)",
    overflow: "hidden",
    cursor: "pointer",
    // background: "var(--neutral-color)",
    transition: "0.25s ease-out",
    // font-size: "1.5em",
  },

  btnStyles: {
    // backgroundColor: 'red  !important',
    // color: 'gray  !important',
    // margin: "5px",
    minWidth: "30px !important",
    maxHeight: "30px !important",
    borderRadius: "15px !important",
    "& .css-8on0cm-MuiButtonBase-root-MuiButton-root": {
      minWidth: "10px !important",
    },
  },

  /////diary mood section
  moodSlider: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-around",
    },
  },
  ////goal input

  editTextDiary: {
    padding: "0px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    transition: "background 0.2s ease",
    minHeight: "28px",
    width: "auto",
    display: "block",
    margin: "0px 0 0px 0",
    border: "none",
    background: "none",
    focus: "none",
    "& focus": {
      border: "none",
    },
  },


  headTex: {
    fontFamily: "Museo700", textAlign: 'center', marginLeft: '10rem',
    color: 'rgb(0 31 88)',
    [theme.breakpoints.down('sm')]: {
      fontFamily: "Museo700", textAlign: 'center', margin: 'auto',
      color: 'rgb(0 31 88)', marginTop: '2rem'
    }

  },

  toolbarClassName: {
    padding: "6px 10px 0",
    borderRadius: "30px",
    border: "1px solid #F1F1F1",
    display: "flex",
    justifyContent: "center",
    background: "white",
    flexWrap: "wrap",
    fontSize: "15px",
    marginBottom: "5px !important",
    userSelect: "none",
    height: "450px !important",
    maxWidth: "140px !important",
    margin: "auto !important",
    float: "left",
    marginRight: "20px !important",
    marginTop: '3px !important',
    [theme.breakpoints.down('sm')]: {
      padding: "6px 10px 0",
      borderRadius: "30px",
      border: "1px solid #F1F1F1",
      display: "flex",
      justifyContent: "center",
      background: "white",
      flexWrap: "wrap",
      fontSize: "15px",

      userSelect: "none",
      height: "200px !important",
      maxWidth: "340px !important",
      margin: "auto",
      float: "left",
      marginRight: "0px !important",
      marginTop: '3rem !important',
      marginBottom: "5rem !important",
    },
  },
  editorClassName: {
    marginLeft: "20px",
    paddingLeft: "10px",
    boxShadow: `0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)`,
    border: "1px solid #EEEEEE",
    display: "block",
    // fontFamily: "'Marck Script',cursive",
    fontSize: "22px",
    lineHeight: "30px",
    margin: "2% auto",
    resize: "none",
    backgroundImage: [
      "-webkit-linear-gradient(top , transparent, transparent 49px, #9e9e9e1a 0)",
    ],
    WebkitBackgroundSize: "100% 50px",
    backgroundSize: "100% 50px",
    maxHeight: "430px",
    minHeight: "430px",
    "& img": {
      width: "200px",
      height: "200px",
    },
    "& .colorPickerDiv": {
      position: 'absolute !important'
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px !important"
    }
  },
  editorClassNameOnHide: {
    minHeight: "430px",
    maxHeight: "430px",
    marginLeft: "160px",
    paddingLeft: "10px",
    boxShadow: `0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)`,
    border: "1px solid #EEEEEE",
    display: "block",
    fontFamily: "'Marck Script',cursive",
    fontSize: "22px",
    lineHeight: "30px",
    margin: "2% auto",
    resize: "none",
    backgroundImage: [
      "-webkit-linear-gradient(top , transparent, transparent 49px,#9e9e9e1a 0)",
    ],
    WebkitBackgroundSize: "100% 50px",
    backgroundSize: "100% 50px",
    "& img": {
      width: "200px",
      height: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px !important",

    }
  },
  toolbarClassNameTwo: {
    padding: "6px 10px 0",
    borderRadius: "30px",
    border: "1px solid #F1F1F1",
    display: "flex",
    justifyContent: "center",
    background: "white",
    flexWrap: "wrap",
    fontSize: "15px",
    marginBottom: "5px",
    userSelect: "none",
    height: "450px",
    maxWidth: "140px",
    float: "left",
    position: "absolute",
    left: 10,
    [theme.breakpoints.down('sm')]: {
      padding: "6px 10px 0",
      borderRadius: "30px",
      border: "1px solid #F1F1F1",
      display: "flex",
      justifyContent: "center",
      background: "white",
      flexWrap: "wrap",
      fontSize: "15px",

      userSelect: "none",
      height: "200px !important",
      maxWidth: "340px !important",
      margin: "auto",
      float: "left",
      marginRight: "0px !important",
      marginTop: '3rem !important',
      marginBottom: "5rem !important",
    },
  },

  comingsoonBtnStyle: {
    width: '25vw !important',
    borderRadius: "5rem",
    fontSize: "1.2rem",
    fontWeight: 700,
    marginTop: "3rem !important",
    background: "white",
    color: "#001f58",
    padding: "15px 25px 15px 20px",
    [theme.breakpoints.down("sm")]: {
      width: '70vw !important',
      borderRadius: "5rem",
      fontSize: "1.2rem",
      fontWeight: 700,
      marginTop: "4rem !important",
      background: "white",
      color: "#001f58",
      padding: "15px 25px 15px 20px",
    }

  },

  goalsStyling: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: "#0B2F64",
    minHeight: "28px",
    width: "auto",
    display: "block",
    border: "none",
    background: "none",
    autoFocus: false,
    [theme.breakpoints.down("sm")]: {
      width: "20vw",
      overflow: "hidden !important",
      textOverflow: "ellipsis !important",
      whiteSpace: "nowrap !important",
      color: "#0B2F64",
      minHeight: "28px",
      display: "block",
      border: "none",
      background: "none",
      autoFocus: false,

    }

  },
  headText1: {
    fontFamily: "Museo700",
    textAlign: 'center',
    marginLeft: '0.2rem', color: 'rgb(0 31 88)',
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Museo700",
      textAlign: 'center',
      marginLeft: '0.2rem', color: 'rgb(0 31 88)', marginTop: '3rem'
    }
  },

  toolbarHidden: {
    display: "none",
    visibility: "hidden",
  },
  editorClassNameTwo: {
    marginRight: "20px",
    paddingLeft: "10px",
    boxShadow: `0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)`,
    border: "1px solid #EEEEEE",
    display: "block",
    fontFamily: "'Marck Script',cursive",
    fontSize: "22px",
    lineHeight: "30px",
    margin: "2% auto",
    resize: "none",
    backgroundImage: [
      "-webkit-linear-gradient(top , transparent, transparent 49px, #9e9e9e1a 0)",
      // "-moz-linear-gradient(top , transparent, transparent 49px,gray 0px)",
    ],
    // "&.makeStyles-editorClassName-32":{
    //   maxHeight:'430px',
    // },
    WebkitBackgroundSize: "100% 50px",
    backgroundSize: "100% 50px",
    maxHeight: "430px",
    width: '36vw',
    minHeight: "430px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "3px",
      paddingLeft: "10px",
      boxShadow: `0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)`,
      border: "1px solid #EEEEEE",
      display: "block",
      // fontFamily: "'Marck Script',cursive",
      fontSize: "22px",
      lineHeight: "30px",
      margin: "8% auto",
      resize: "none",
      backgroundImage: [
        "-webkit-linear-gradient(top , transparent, transparent 49px, #9e9e9e26 0)",
        // "-moz-linear-gradient(top , transparent, transparent 49px,gray 0px)",
      ],
      // "&.makeStyles-editorClassName-32":{
      //   maxHeight:'430px',
      // },
      WebkitBackgroundSize: "100% 50px",
      backgroundSize: "100% 50px",
      maxHeight: "430px",
      width: '95vw',
      minHeight: "430px",
    },
    "& img": {
      maxWidth: "200px",
      maxHeight: "200px",
      overflow: "auto",
    },
  },

  // Editor style code//
  wrapperClassNameMobile: {
    // "& .rdw-embedded-wrapper": {
    //   display: "none",
    // },
    // "& .rdw-image-wrapper": {
    //   display: "none",
    // },
    // "& .rdw-remove-wrapper": {
    //   display: "none",
    // },
    // "& .rdw-emoji-wrapper": {
    //   display: "none",
    // },
    // "& .rdw-link-wrapper": {
    //   display: "none",
    // },
    '& .rdw-inline-wrapper': {
      display: "flex",
      alignItems: "center",
      marginBottom: "6px",
      flexWrap: "wrap",
      marginTop: "2rem !important"
    },
    '& .rdw-colorpicker-wrapper': {
      display: "flex",
      alignItems: "center",
      position: "absolute !important",
      flexWrap: "wrap",
      margin: 'auto !important',
      textAlign: 'center',
      align: 'center',
      justifyContent: 'center'
    },

    '& .makeStyles-toolbarClassName-37': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    '& .makeStyles-editorClassName-39': {
      marginLeft: '0px !important',
    },
    '& .makeStyles-editorClassName-133': {
      marginLeft: "0px !important",
    },
    '& .makeStyles-editorClassName-86': {
      marginLeft: '0px !important'
    },
    '& .makeStyles-editorClassNameOnHide-134': {
      marginLeft: "0px !important",
    },
    '& .makeStyles-editorClassNameOnHide-40': {
      marginLeft: '0px !important',
      marginTop: "34px"
    },
    '& .makeStyles-toolbarClassNameTwo-40': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    '& .makeStyles-editorClassNameTwo-187': {
      width: "95% !important",
    },
    '&.makeStyles-editorClassNameTwo-46': {
      width: "95% !important",
    },
    '& .makeStyles-headTex-37': {
      color: 'rgb(0 31 88)',
      /* position: absolute; */
      /* margin-top: -11rem; */
      textAlign: 'center !important',
      // fontFamily: 'Museo700',
      /* margin-left: 10rem; */
      margin: "auto !important",
      marginTop: '2rem !important',
    }
  },
  wrapperClassName: {
    // "& .rdw-embedded-wrapper": {
    //   display: "none",
    // },
    // "& .rdw-image-wrapper": {
    //   display: "none",
    // },
    // "& .rdw-remove-wrapper": {
    //   display: "none",
    // },
    // "& .rdw-emoji-wrapper": {
    //   display: "none",
    // },
    // "& .rdw-link-wrapper": {
    //   display: "none",
    // },
    '& .rdw-inline-wrapper': {
      display: "flex",
      alignItems: "center",
      marginBottom: "6px",
      flexWrap: "wrap",
      marginTop: "2rem !important"
    },
    '& .rdw-colorpicker-wrapper': {
      display: "flex",
      alignItems: "center",
      position: "absolute !important",
      flexWrap: "wrap",
      margin: 'auto !important',
      textAlign: 'center',
      align: 'center',
      justifyContent: 'center'
    },
    '& .makeStyles-toolbarClassName-37': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    '& .makeStyles-toolbarClassNameTwo-40': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    }
  },
  colorPickerDiv: {
    position: 'absolute !important'
  },
  deleteStyle: {
    '& .css-518kzi-MuiListItemSecondaryAction-root': {
      [theme.breakpoints.down("sm")]: {
        position: 'absolute',
        right: '16px',
        top: '74% !important',
      }
    }
  },

  datePickerStyle: {
    '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
      borderRadius: 0,
    },
    '& .css-1eemxsk-MuiInputBase-root-MuiOutlinedInput-root': {
      width: '11vw',
      [theme.breakpoints.down("sm")]: {
        width: 'auto',
      },

      // '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root':{

      // },

      '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
        [theme.breakpoints.down("sm")]: {
          padding: '0!important'
        }
      }

      // }
    },
  },
}));

export default DiaryStyles;