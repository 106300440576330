import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useFirestoreConnect } from "react-redux-firebase";

import {
  Grid, Typography, AppBar, Box, Toolbar, IconButton, Menu,
  Paper, Container, Avatar, Button, Tooltip, MenuItem, InputBase
} from '@mui/material'

import { styled, alpha } from '@mui/material/styles';

import MenuIcon from '@mui/icons-material/Menu';
import AdbIcon from '@mui/icons-material/Adb';
import SearchIcon from '@mui/icons-material/Search';
import MapsUgcOutlinedIcon from '@mui/icons-material/MapsUgcOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

import { logout, manualSignUp, setActivePage, setDefaultRoles, setInstitute, setUser } from '../../store/actions/user';
import { setSearchQuery } from '../../store/actions/library'

import ProfilePic from '../../assets/images/Profile/2.jpg'
import InstituteLogo from '../../assets/images/logo.jpeg'
import Logo from '../../assets/images/logo.png'
import { setDefaultPemissionList } from '../../store/actions/permissions';
import ComplaintAccept from '../complaintsPortal/ComplaintAccept';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '20px',
  border: "1px solid gray",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const pages = ['Dashboard', 'Library', 'Site Administration', 'Hub'];
const pagesSecond = ['Dashboard', 'Library', 'Hub'];

const pagesHamburger = ['Dashboard', 'Library', 'Site Administration', "Profile", "Notifications"];
const pagesSecondHamburger = ['Dashboard', 'Library', "Profile", "Notifications"];

// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
const settings = ['Profile', 'Dashboard', 'Logout'];

const pagesLinks = ["/dashboard", "/library", "/user-organization", '/hub/articles']
const pagesHamburgerLinks = ["/dashboard", "/library", "/hub/articles", "/profile"]
const settingLinks = ["/profile", "/dashboard"]

const Navbar = () => {
  const dispatch = useDispatch()
  const history = useHistory();
  const [pagesToShow, setPagesToShow] = useState(pages)
  const [pagesToShowHamburger, setPagesToShowHamburger] = useState(pagesHamburger)
  const auth = useSelector((state) => state.firebase.auth);
  const [avatarUrl, setAvatarUrl] = useState('')
  const { searchQuery } = useSelector(state => state.library)
  const { firestore } = useSelector((state) => state);
  const { institute, activePageIndex, user } = useSelector(state => state.user)
  const { institutes, users } = firestore.ordered;
  const [openComplaint, setOpenComplaint] = useState(false)

  useFirestoreConnect([{ collection: "users", doc: auth.uid }]);
  useFirestoreConnect([{ collection: "institutes", doc: auth.uid }]);

  // useEffect(() => {
  //   dispatch(setInstitutePermissions())
  // }, [])

  useEffect(() => {
    if (institutes && institutes.length > 0) {
      let currentUser = institutes[0]
      setAvatarUrl(currentUser?.avatarUrl)
      dispatch(setInstitute(currentUser))
    } else if (users && users.length > 0) {
      let currentUser = users[0]
      setAvatarUrl(currentUser?.avatarUrl)
      dispatch(setUser(currentUser))
      if (users[0].isStudent) {
        setPagesToShow([...pagesSecond])
        setPagesToShowHamburger([...pagesSecondHamburger])
      }
    }
  }, [institutes, users])

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (index) => {
    setAnchorElNav(null);
    if (index !== null) {
      if (user.isStudent) {
        history.push(pagesHamburgerLinks[index])
      } else {
        history.push(pagesLinks[index])
      }
    }
    dispatch(setActivePage(index))
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);

  };

  const onClickLogout = () => {
    dispatch(logout());
    history.push('/');
  }
  const handleOnClickSettings = (index) => {
    setAnchorElUser(null);
    if (index === 0) {
      dispatch(setActivePage(-1))
    } else {
      dispatch(setActivePage(0))
    }
    if (index === 2) {
      onClickLogout();
    } else {
      history.push(settingLinks[index])
    }
  }

  return (
    <>
      <AppBar position="static" style={{ background: "#fff", color: '#000', position: "fixed", zIndex: 999 }} elevation={1}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <img
              src={Logo}
              alt="logo"
              sx={{ transform: "scale(4)" }}
              onClick={() => history.push("/")}
              style={{ cursor: "pointer" }}
            />
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none', justifyContent: "right", width: "100%" } }} >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={() => handleCloseNavMenu(null)}
                sx={{
                  display: { xs: 'block', md: 'none' },
                  zIndex: 9999
                }}>
                {pagesToShowHamburger.map((page, index) => (
                  <MenuItem key={page} onClick={() => handleCloseNavMenu(index)}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} style={{ paddingLeft: '5rem' }}>
              {pagesToShow.map((page, index) => (
                <Button
                  key={page}
                  onClick={() => handleCloseNavMenu(index)}
                  sx={{
                    my: 2, color: "#1E3A71",
                    fontFamily: activePageIndex === index ? "Museo700" : "Museo300",
                    backgroundColor: activePageIndex === index ? '#C0FFFF' : 'none',
                    display: 'block', padding: '10px 20px', margin: '0px 5px',
                    borderRadius: '20px'
                  }}
                >
                  {page}
                </Button>
              ))}

            </Box>

            <Box sx={{ flexGrow: 0, display: "flex", display: { xs: 'none', sm: 'none', md: 'flex' } }}  >

              <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", marginRight: "1rem" }} sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                {
                  activePageIndex === 1 &&
                  <Search style={{ width: '300px' }}>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ 'aria-label': 'search' }}
                      onChange={(e) => dispatch(setSearchQuery(e.target.value))}
                      value={searchQuery}
                    />
                  </Search>
                }

                <NotificationsNoneOutlinedIcon style={{ color: "#1E3A71", fontWeight: 700, fontSize: 30, }} />
                <MapsUgcOutlinedIcon onClick={() => { setOpenComplaint(true) }} style={{ color: "#1E3A71", cursor: 'pointer', fontWeight: 700, fontSize: 30, }} />
              </Box>

              <Grid item xs={12} component={Paper}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'start',
                  border: '1px solid gray',
                  padding: '0px 5px',
                  marginTop: '10px',
                  textAlign: 'left',
                  height: '40px',
                  margin: '0px 10px',
                }}>
                <img src={institute?.instituteLogoUrl || InstituteLogo} alt="logo" style={{ width: '30px', height: '30px', margin: 'auto 0px', borderRadius: '15px' }} />
                <Typography style={{ color: '#000', margin: 'auto 0px', marginLeft: '10px', fontFamily: 'Museo700' }}>
                  {institute?.instituteName}
                </Typography>
              </Grid>

              <Tooltip title="Account">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src={avatarUrl} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px', zIndex: 10000 }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting, index) => (
                  <MenuItem key={setting} onClick={() => handleOnClickSettings(index)}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <ComplaintAccept open={openComplaint} setOpen={setOpenComplaint} />
    </>
  );
};
export default Navbar;
