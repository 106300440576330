import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Container, Box, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import DashboardStyles from "./DashboardStyles";
// import Footer from "../../../Footer";
import AppBlogOfTheWeek from "./sections/AppBlogOfTheWeek";
import AppRecentlyAddedComp from "./sections/AppRecentlyAddedComp";
import RiddleComponent from "./sections/RiddleComponent";
import NewAppToDo from "./sections/NewAppToDo";
import AppMyRoom from "./sections/AppMyRoom";
import StartComp from "./sections/StartComp";
import ShortStoryMasterClass from "./sections/ShortStoryMasterClass";
import AppEarnedBadges from "./sections/AppEarnedBadges";
import AppShoutOut from "./sections/AppShoutOut";
import AppPremiumBadge from "./sections/AppPremiumBadge";
import AppNavComp from "./sections/AppNavComp";
import add2 from "../../assets/images/dashboard/2.png";
import add1 from "../../assets/images/dashboard/1.png";
import { getWallOfFamePosts, saveCategories } from "../../store/actions/posts";
import { getUserLogs } from "../../store/actions/rewards";
import CreateFabButton from "./CreateFabButton";
import { getUserSubscription, addUserSubscription } from "../../store/actions/subscriptions";
import MemesBox from "./sections/MemesBox";
import { addDraftToPostcard } from "../../store/actions/postCards";
import Navbar from "../Navbar";
import Main from "../Main";

const styles = {
  backgroundColor: '#fff',
  backgroundImage: 'none',
  paddingBottom: 50
}

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const classes = DashboardStyles();
  const { wallOfFamePosts } = useSelector((state) => state.posts);
  const { userLogs } = useSelector((state) => state.rewards);
  const { subscriptions, userSubscription, isPremiumUser } = useSelector((state) => state.subscriptions);

  useEffect(() => {
    dispatch(getUserLogs())
    // dispatch(saveCategories())
    // dispatch(getSubscription())
    // dispatch(addUserSubscription())
    // dispatch(addDraftToPostcard())
    dispatch(getUserSubscription())
  }, [])

  useEffect(() => {
    dispatch(getWallOfFamePosts());
  }, [wallOfFamePosts])

  return (
    <Main styles={styles}>
      <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid item xs={12} md={12} style={{ minHeight: '80px' }}>
          <Navbar />
        </Grid>
        <Grid container item xs={12} md={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Container maxWidth="xl" >
            <Grid container spacing={2} className={classes.mainContainerStyle2}>

              <Grid item xs={12} sm={6} md={3} lg={2}>
                <Grid item xs={12} display={{ xs: "block", sm: "block", md: "none", lg: "none" }}>
                  <StartComp className={classes.hiddenOnMobileStyle} />
                </Grid>
                <AppPremiumBadge />
                <RiddleComponent />
                <br />
                <NewAppToDo />
                <AppMyRoom />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={8}>
                <Grid item md={12} sm={6} display={{ xs: "none", sm: "none", md: "block", lg: "block" }}>
                  <StartComp className={classes.hiddenOnMobileStyle} />
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={3} lg={5}>
                    <ShortStoryMasterClass />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3} lg={7}>
                    <AppEarnedBadges />

                    <Grid item md={12} sm={6} style={{ marginTop: '1rem' }} display={{ xs: "none", sm: "none", md: "block", lg: "block" }}>
                      <AppShoutOut />
                    </Grid>

                  </Grid>
                </Grid>

                <Grid item md={12} sm={6} style={{ marginTop: '0.5rem', display: "flex" }} display={{ xs: "none", sm: "none", md: "block", lg: "block" }}>
                  <Grid container spacing={2} style={{ display: "flex", }}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <MemesBox />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <AppBlogOfTheWeek />
                    </Grid>
                  </Grid>
                </Grid>

                {/* <AppNavComp /> */}
                <AppRecentlyAddedComp />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={2} className={classes.DashboardImgsStyle}>
                <img src={add2} alt="hi"
                  width="100%"
                  style={{ width: '12vw', borderRadius: '10px' }}
                />
                <img
                  src={add1}
                  alt="hi"
                  width="100%"
                  style={{ marginTop: "1rem", width: '12vw', borderRadius: '10px' }}
                />
              </Grid>
            </Grid>
            <Box className={classes.createFabStyles2} >
              <CreateFabButton />
            </Box>
          </Container>
          {/* <Footer /> */}
        </Grid>
      </Grid>
    </Main>
  );
}

export default Dashboard;
