import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Stack,
  TextField,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import DiaryStyles from "./DiaryStyles";
import { useSelector, useDispatch } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InputAdornment from "@mui/material/InputAdornment";

import {
  getHabits,
  saveHabits,
  updateHabits,
  deleteHabit,
} from "../../store/actions/habitTracker";

import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import { showErrorSnackbar } from "../../store/actions/snackbar";

function DiaryHabit() {
  const classes = DiaryStyles();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);
  const [input, setInput] = useState("");
  const [color, setColor] = useState("#a093c7");
  const { habits } = useSelector((state) => state.habit);
  const [datePayload, setDatePayload] = useState([]);
  
  useEffect(() => {
    dispatch(getHabits());
  }, []);

  const datePayloadData = () => {
    let date = moment().format("YYYY-MM-01");
    let daysInMonth = moment().daysInMonth();
    for (let i = 0; i < daysInMonth; i++) {
      let startDate = moment(
        moment(date).format("DD-MM-YYYY"),
        "DD-MM-YYYY"
      ).add(i, "days");

      let array = {
        date: moment(startDate).format("DD-MM-YYYY"),
        status: false,
      };
      datePayload.push(array);
    }
  };

  const addhabit = () => {
    if (input.length < 1) {
      alert("not able to save empty habit");
    } else {
      setDatePayload([]);
      datePayloadData();
      const payload_state = {
        habit: input,
        userId: auth.uid,
        date: datePayload,
        created_at: moment().format("DD-MM-YYYY"),
      };
      setInput("");
      dispatch(saveHabits(payload_state));
    }
  };

  const changeColor = (mainIndex, index) => {
    let habitsDataArray = [...habits];
    let currentHabitData = habitsDataArray[mainIndex];
    let { date } = currentHabitData;
    date[index].status = !date[index].status;

    currentHabitData.date = date
    dispatch(updateHabits(currentHabitData))
  };

  const deleteItem = (id) => {
    dispatch(deleteHabit(id));
  };

  const handelInput = event => {
    const { name, value } = event.target;
    if (value.length > 25) {
      dispatch(showErrorSnackbar("Limit is 25 characters !!!"));
      return
    }
    else {
      setInput(value);
    }
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={3}
      style={{
        borderRadius: "1rem",
        // backgroundColor: '#EDF0F2',
        padding: "1rem",
        marginTop: "1rem",
        display: "block",
      }}
    >
      <Grid>
        <Box>
          <form style={{ marginTop: "-2rem" }}>
            <TextField
              type="text"
              label="Add New Habit"
              variant="outlined"
              style={{
                margin: "auto",
                display: "flex",
                justifyContent: "center",
              }}
              size="small"
              value={input}
              onChange={e => handelInput(e)}
              // onChange={(e) => setInput(e.target.value)}
              // onBlur={addGoal}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start" onClick={addhabit}>
                    <AddCircleIcon
                      style={{ cursor: "pointer", color: "#103d7e" }}
                    />
                  </InputAdornment>
                ),
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  ev.preventDefault();
                  addhabit();
                }
              }}
            />
          </form>

          {/* Map itmes here */}
          {habits?.map((item, mainIndex) => (
            <ListItem key={mainIndex}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid item xs={8} sm={8} md={8}>
                    <ListItemText
                      style={{
                        fontSize: "14px",
                        marginTop: "0.8rem",
                        width: '20vw',
                        // whiteSpace: "nowrap !important",
                        // overflow: "hidden !important",
                        // textOverflow: "ellipsis !important",
                        // width: "20vw",
                        // height: "5vh",
                        // overflow: "hidden",
                      }}
                      id={mainIndex}
                      primary={item.habit}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sm={8}
                    md={4}
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <DeleteIcon
                      fontSize="small"
                      style={{
                        opacity: 0.7,
                        fontSize: "24px",
                        margin: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        deleteItem(item.id);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={4} sm={4} md={12}>
                  <Stack width="100%" justifyContent="space-between">
                    <div className={classes.DownLeftMini}>
                      {item.date.map((interest, index) => (
                        <Button
                          className={classes.btnStyles}
                          style={{
                            background:
                              parseInt(moment().date()) - 1 < index
                                ? "gray"
                                : interest.status
                                  ? "green"
                                  : color,
                            color: interest.status ? "#fff" : '#fff',
                            margin: "2px",
                          }}
                          disabled={parseInt(moment().date()) - 1 < index}
                          onClick={() =>
                            parseInt(moment().date()) - 1 < index
                              ? null
                              : changeColor(mainIndex, index)
                          }
                        >
                          {" "}
                          {index + 1}
                        </Button>
                      ))}
                    </div>
                  </Stack>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </Box>
      </Grid>
    </Stack>
  );
}

export default DiaryHabit;
