import { Dialog, FormControl, Grid, MenuItem, Select } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { raiseComplaint } from '../../store/actions/complaints'
import { showErrorSnackbar } from '../../store/actions/snackbar'

const ComplaintAccept = ({open,setOpen}) => {
  const dispatch = useDispatch()
  const [payload,setPayload] = useState({
    title:"",
    complaintCategory:"Suggestion",
    discription:""
  })
  const addComplaint = ()=>{
    if(!payload.title){
      dispatch(showErrorSnackbar("Please give a title for complaint"))
    }
    else if(!payload.complaintCategory){
      dispatch(showErrorSnackbar("Please select type of complaint"))
    }
    else{
      dispatch(raiseComplaint(payload))
    }
  }
  const handleInputChange = (e)=>{
    const {name,value} = e.target
    setPayload(prev=>({
      ...prev,
      [name]:value
    }))
  }
  const handleClose =()=>{
    setPayload({
      title:"",
      complaintCategory:"Suggestion",
      discription:""
    })
    setOpen(false)
  }
  return (
    <Dialog open = {open} onClose={handleClose}>
        <Grid style = {{padding:'20px', width:'300px'}}>
          <Grid style = {{marginBottom:'20px',textAlign:'center',fontSize:'16px', fontWeight:'600',color:'#1E3B72' }} >Having some issue?</Grid>
          <p style={{fontWeight:'500',marginTop:'5px',color:'#707070',fontSize:'12px'}}>Category of complaint:</p>
          <FormControl style = {{marginBottom:'5px'}} fullWidth>
            {/* <InputLabel id="demo-simple-select-label"></InputLabel> */}
            <Select
              required
              value={payload.complaintCategory}
              label="Complaint Category"
              name = 'complaintCategory'
              onChange={handleInputChange}
            >
              <MenuItem value={'Suggestion'}>Suggestion</MenuItem>
              <MenuItem value={'Technical Error'}>Technical Error</MenuItem>
              <MenuItem value={'Complaint'}>Complaint</MenuItem>
            </Select>
          </FormControl>
          <p style={{fontWeight:'500',marginTop:'5px',color:'#707070',fontSize:'12px'}}>Title of Complaint:</p>
          <input style = {{marginBottom:'5px',borderRadius:'10px',width:'250px',height:'40px' ,padding:'5px',border:'1px solid #9F9F9F',outline:'none'}} value = {payload.title} onChange = {handleInputChange} name='title' autoFocus required/>
          <p style={{fontWeight:'500',marginTop:'5px',color:'#707070',fontSize:'12px'}}>Discription:</p>
          <textarea
          style = {{marginBottom:'5px',borderRadius:'10px',width:'250px',height:'100px' ,padding:'5px',border:'1px solid #9F9F9F',outline:'none'}}
          name='discription'
          value={payload.discription}
          onChange={handleInputChange}
          />
          <Grid style = {{margin:'5px',display:'flex',justifyContent:'space-between'}}>
            <button onClick={handleClose} style ={{cursor:'pointer',background:'white',borderRadius:'30px',width:'90px',height:'36px',border:'1px solid #E0E0E0',color:'#242424'}}>X Cancel</button>
            <button style ={{cursor:'pointer',background:'linear-gradient(180deg, #FFCD51 0%,#FEB231 100%)',boxShadow:'4px 8px 20px rgba(0, 0, 0, 0.1)',borderRadius:'30px',width:'123px',height:'36px',border:'none',color:'white',fontWeight:'700'}} onClick={addComplaint}>Add Complaint</button>
          </Grid>
        </Grid>
    </Dialog>
  )
}

export default ComplaintAccept