import React from "react";
import DiaryStyles from "./DiaryStyles";
import Happy from "../../assets/images/Happy.png";
import Neutral from "../../assets/images/Neutral.png";
import Sad from "../../assets/images/Sad.png";
import VeryHappy from "../../assets/images/VeryHappy.png";
import { Grid, Typography, Card, Slider } from "@mui/material";

function DiaryMoodSection(props) {
  const classes = DiaryStyles();
  function preventHorizontalKeyboardNavigation(event) {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
    }
  }

  const handleMoodChange = (value) => {
    props.setMoodValue(value)
  }
  return (
    <>
      <Grid xs={12} md={12} sm={12} lg={12}>
        <Card
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: "2rem",
            padding: "1rem",
            backgroundColor: "#DBDBDB",
            fontFamily: "Museo700",
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            textAlign="center"
            component="div"
            fontFamily="Museo700"
            color="#0B2F64"
          >
            Mood Today
          </Typography>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid xs={4} md={4} lg={5} >
              <img
                src={VeryHappy}
                className={classes.moodStyling}
                position="absolute"                
                onClick={()=>handleMoodChange(4)}
                style={{marginTop:'0.5rem'}}
              />
              <img
                src={Happy}
                className={classes.moodStyling}
                position="absolute"                
                onClick={()=>handleMoodChange(3)}

              />
              <img
                src={Neutral}
                className={classes.moodStyling}
                position="absolute"                
                onClick={()=>handleMoodChange(2)}

              />
              <img
                src={Sad}
                className={classes.moodStyling}
                position="absolute"                
                onClick={()=>handleMoodChange(1)}

              />
            </Grid>

            <Grid xs={4} md={4} lg={5} style={{paddingTop:'0.4rem', paddingBottom:'4rem'}}>
              <Slider className={classes.sliderStyle}
                orientation="vertical"
                value={props.moodValue}
                min={1}
                step={1}
                max={4}
                aria-label="Temperature"
                onKeyDown={preventHorizontalKeyboardNavigation}
                onChange={(e) => handleMoodChange(e.target.value)}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
}

export default DiaryMoodSection;
