import { actionTypes } from '../actions';


export const INITIAL_STATE = {
	openedDiary: {
		diaryId: null,
		page: null,
		// secondPage: null,
		on: null,
		userid: null,
		feelings: 'happy',
		color: '#a093c7'
	},
	savedDiary: {
		diaryId: null,
		page: null,
		// secondPage: null,
		on: null,
		userid: null,
		feellings: null
	},
	diaryDates: [],
};

export const diary = (state = INITIAL_STATE, action) => {
	const { type, payload } = action;

	switch (type) {
		case actionTypes.SET_OPENED_DIARY:
			return {
				...state,
				openedDiary: payload,
			};
		case actionTypes.SET_GET_DIARY_PAGE:
			return {
				...state,
				openedDiary: payload,
			};
		case actionTypes.SET_ADD_DIARY_PAGE:
			return {
				...state,
				openedDiary: payload,
			};
		case actionTypes.SET_SAVED_DIARY:
			return {
				...state,
				savedDiary: payload,
			};
		case actionTypes.SET_DIARY_DATES:
			return {
				...state,
				diaryDates: payload,
			};
		default:
			return state;
	}
};