import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import add1 from "../../../assets/images/dashboard/1.png";
import add2 from "../../../assets/images/dashboard/2.png";
import Guide from "../BlogsPages/Guide";
import { useSelector, useDispatch } from "react-redux";

import {
    Container,
    Grid,
    Box,
    Tab,
    Typography,
    Card,
} from "@mui/material";

import { TabContext, TabList, TabPanel } from "@mui/lab";

import Footer from "../../Footer";
import HubPagesStyles from "./HubPagesStyles";
import Article from "../BlogsPages/Article";
import GuideSubpages from "../BlogsPages/GuideSubpages";
import { isLoaded, isEmpty } from "react-redux-firebase";
import Main from "../../Main";
import Navbar from "../../Navbar";

const styles = {
    backgroundColor: '#fff',
    backgroundImage: 'none',
    paddingBottom: 50
}

function HubPages() {
    const classes = HubPagesStyles();
    const history = useHistory();
    const [value, setValue] = useState(0);
    const [showArticle, setShowArticle] = useState(false);
    const [articleValue, setArticleValue] = useState(null)
    const auth = useSelector((state) => state.firebase.auth);
    const isAuthenticated = isLoaded(auth) && !isEmpty(auth);
    const { article } = useParams()

    useEffect(() => {
        switch (article) {
            case 'online-personal-diary':
                setValue(0);
                setArticleValue(0);
                break;
            case 'benefits-of-writing-diary':
                setValue(0);
                setArticleValue(1);
                break;
            case 'types-of-diary':
                setValue(0);
                setArticleValue(2);
                break;
            case 'history-of-postcards':
                setValue(0);
                setArticleValue(3);
                break;
            case 'history-of-poetry':
                setValue(0);
                setArticleValue(4);
                break;
            case 'articles':
                setValue(0);
                setArticleValue(null);
                break;
            case 'guide':
                setValue(1);
                break;
            case 'faqs':
                setValue(2);
                break;
            default:
                setValue(0);
                break;
        }
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
        switch (newValue) {
            case 0:
                history.push('/hub/articles');
                break;
            case 1:
                history.push('/hub/guide');
                break;
            case 2:
                history.push('/hub/faqs');
                break;
        }
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Main styles={styles}>
            <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
                {
                    isAuthenticated &&
                    <Grid item xs={12} md={12} style={{ minHeight: '80px' }}>
                        <Navbar />
                    </Grid>
                }

                <Container maxWidth="xl" style={{ marginTop: isAuthenticated ? '0px' : '85px' }}>
                    <Box
                        item
                        sx={{
                            flexGrow: 1,
                            width: "100%",
                            display: "flex",
                            position: "relative",
                        }}
                    >
                        <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center', }}>
                            <Grid container item xs={12} md={12}>
                                <Grid item xs={12} md={10} lg={10} style={{ marginTop: "0.5rem" }}>
                                    <TabContext value={value} width="100%">
                                        <Box
                                            sx={{
                                                borderBottom: 1,
                                                borderColor: "divider",
                                                textAlign: "center",
                                                width: "100%",
                                            }}
                                        >
                                            <TabList className={classes.tabBTN}
                                                centered
                                                onChange={handleChange}
                                                aria-label="lab API tabs example"
                                            // variant="fullWidth"
                                            >
                                                <Tab label="Articles" value={0} style={{ fontFamily: "Museo700" }} />
                                                <Tab label="Guide" value={1} style={{ fontFamily: "Museo700" }} />
                                                <Tab label="FAQ" value={2} style={{ fontFamily: "Museo700" }} />

                                            </TabList>
                                        </Box>
                                        <TabPanel
                                            value={0}
                                            spacing={2}
                                            sx={{ display: "flex", justifyContent: "space-between" }}
                                        >
                                            <Grid xs={12} sm={12} md={12} lg={12}>
                                                <Article
                                                    showArticle={showArticle}
                                                    setShowArticle={setShowArticle}
                                                    articleValue={articleValue}
                                                    setArticleValue={setArticleValue}
                                                />
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel value={1} sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Grid xs={12} sm={12} md={12} lg={12}>
                                                {/* <NewDiary /> */}
                                                <GuideSubpages />
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel value={2} sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Grid xs={12} sm={12} md={12} lg={12}>
                                                <Guide />
                                            </Grid>
                                        </TabPanel>
                                    </TabContext>
                                </Grid>
                                <Grid item xs={12} md={4} lg={2} className={classes.exploreStyle}>
                                    {/* <Typography
                                        component="div"
                                        marginBottom="1.5rem"
                                        color="#545454"
                                        style={{ textAlign: "center", fontSize: "1.5rem", }}
                                    >
                                        Explore topics
                                    </Typography> */}


                                    <Card className={classes.addImges}>
                                        {/* <img src={ad} width="100%" /> */}
                                        <img
                                            src={add1}
                                            alt="hi"
                                            width="100%"
                                            className={classes.hidenImg1}
                                        />
                                    </Card>
                                    <Card className={classes.addImges}>
                                        {/* <img src={feather} width="100%" /> */}
                                        <img
                                            src={add2}
                                            alt="hi"
                                            width="100%"
                                            className={classes.hidenImg1}
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Footer />
                </Container>
            </Grid>
        </Main>
    );
}

export default HubPages;
