
export const actionTypes = {
	//login
	LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
	SET_USER_SEARCH_QUERY: 'SET_USER_SEARCH_QUERY',

	//blogs
	GET_BLOGS: 'GET_BLOGS',
	SET_OPENED_BLOG: "SET_OPENED_BLOG",
	SET_SAVED_BLOG: "SET_SAVED_BLOG",
	SET_LIST_BLOGS: "SET_LIST_BLOGS",
	SET_SAVED_BLOG_STATUS: 'SET_SAVED_BLOG_STATUS',
	GET_DRAFT_BLOGS: 'GET_DRAFT_BLOGS',

	//posts
	GET_POSTS: 'GET_POSTS',
	GET_USER_POSTS: 'GET_USER_POSTS',
	SET_OPENED_POST: 'SET_OPENED_POST',
	SET_SAVED_POST: 'SET_SAVED_POST',
	GET_USER_LIKED_POSTS: 'GET_USER_LIKED_POSTS',
	GET_TRENDING_POSTS: 'GET_TRENDING_POSTS',
	GET_WALL_OF_FAME_POSTS: 'GET_WALL_OF_FAME_POSTS',
	GET_USER_PUBLISHED_POSTS: 'GET_USER_PUBLISHED_POSTS',
	GET_USER_DRAFT_POSTS: 'GET_USER_DRAFT_POSTS',
	SET_USER_DRAFT_POST_EDIT: 'SET_USER_DRAFT_POST_EDIT',
	GET_BLOG_OF_THE_WEEK: 'GET_BLOG_OF_THE_WEEK',
	GET_FEATURED_POSTS: 'GET_FEATURED_POSTS',
	GET_GLOBAL_POSTS: 'GET_GLOBAL_POSTS',
	GET_USER_BLOG_TITLES: 'GET_USER_BLOG_TITLES',

	//postcards
	GET_RECEIVED_POSTCARDS: 'GET_RECEIVED_POSTCARDS',
	GET_SENT_POSTCARDS: 'GET_SENT_POSTCARDS',
	SET_OPENED_POSTCARD: 'SET_OPENED_POSTCARD',
	SET_SAVED_POSTCARD: 'SET_SAVED_POSTCARD',
	SET_POSTCARD_DETAILS: 'SET_POSTCARD_DETAILS',
	SET_POSTCARD_IMAGE: 'SET_POSTCARD_IMAGE',
	GET_DRAFT_POSTCARDS: 'GET_DRAFT_POSTCARDS',
	SAVE_DRAFT_POSTCARDS: 'SAVE_DRAFT_POSTCARDS',
	GET_SENT_POSTCARDS_COUNT: 'GET_SENT_POSTCARDS_COUNT',
	GET_RECEIVED_POSTCARDS_COUNT: 'GET_RECEIVED_POSTCARDS_COUNT',
	GET_DRAFT_POSTCARDS_COUNT: 'GET_DRAFT_POSTCARDS_COUNT',
	SET_DRAFT_EDIT_FLAG: 'SET_DRAFT_EDIT_FLAG',

	//comments
	GET_COMMENTS: 'GET_COMMENTS',
	SET_OPENED_COMMENT: "SET_OPENED_COMMENT",
	SET_NEW_COMMENT: "SET_OPENED_COMMENT",
	SET_SAVED_COMMENT: "SET_SAVED_COMMENT",
	SET_LIST_COMMENT: "SET_LIST_COMMENT",
	GET_LATEST_COMMENTS: 'GET_LATEST_COMMENTS',
	GET_COMMENTS_BY_POSTS: 'GET_COMMENTS_BY_POSTS',

	//diary
	SET_OPENED_DIARY: 'SET_OPENED_DIARY',
	SET_GET_DIARY_PAGE: 'SET_GET_DIARY_PAGE',
	SET_ADD_DIARY_PAGE: 'SET_ADD_DIARY_PAGE',
	SET_SAVED_DIARY: 'SET_SAVED_DIARY',
	SET_DIARY_DATES: 'SET_DIARY_DATES',

	//journal
	SET_OPENED_JOURNAL: 'SET_OPENED_JOURNAL',
	SET_GET_JOURNAL_PAGE: 'SET_GET_JOURNAL_PAGE',
	SET_SAVED_JOURNAL: 'SET_SAVED_JOURNAL',
	SET_ADD_JOURNAL_PAGE: 'SET_ADD_JOURNAL_PAGE',
	SET_JOURNAL_ID: 'SET_JOURNAL_ID',


	//snackbar
	SNACKBAR_SUCCESS: 'SNACKBAR_SUCCESS',
	SNACKBAR_ERROR: 'SNACKBAR_ERROR',
	SNACKBAR_INFO: 'SNACKBAR_INFO',
	SNACKBAR_CLEAR: 'SNACKBAR_CLEAR',
	IS_PAGE_LOADING: 'IS_PAGE_LOADING',

	//Riddles
	SET_OPENED_RIDDLE: 'SET_OPENED_RIDDLE',

	//images
	SET_OPENED_GALLERY: 'SET_OPENED_GALLERY',
	SET_OPENED_USERS_IMAGES: 'SET_OPENED_USERS_IMAGES',
	SET_LATEST_USERS_IMAGES: 'SET_LATEST_USERS_IMAGES',
	SET_PROFILE_IMAGES: 'SET_PROFILE_IMAGES',
	GET_PREMIUM_IMAGES: 'GET_PREMIUM_IMAGES',
	GET_STICKERS: 'GET_STICKERS',

	//todo
	SET_ADD_TODOS: 'SET_ADD_TODOS',
	CLEAR_TODO_INPUT: 'CLEAR_TODO_INPUT',
	GET_TODOS: 'GET_TODOS',

	//goals
	GET_GOALS: 'GET_GOALS',
	SET_ADD_GOALS: 'SET_ADD_GOALS',
	CLEAR_ADD_GOALS: 'CLEAR_ADD_GOALS',
	SET_OPENED_GOALS: 'SET_OPENED_GOALS',

	//Received PostCard
	RECEIVED_POSTCARD: 'RECEIVED_POSTCARD',

	//COUNTS
	COUNTS: 'COUNTS',

	//Aspirations
	SET_GET_ASPIRATIONS: 'SET_GET_ASPIRATIONS',
	SET_ADD_ASPIRATIONS: 'SET_ADD_ASPIRATIONS',

	//Rewards
	GET_USER_QUINNS: 'GET_USER_QUINNS',
	SET_USER_LOGS: 'GET_USER_LOGS',
	ADD_USER_LOGS: 'ADD_USER_LOGS',
	UPDATE_USER_LOGS: 'UPDATE_USER_LOGS',
	SET_USER_QUINNS: 'SET_USER_QUINNS',

	//Habit Tracker
	GET_USER_HABITS: 'GET_USER_HABITS',
	SET_USER_HABITS: 'SET_USER_HABITS',
	UPDATE_USER_HABITS: 'UPDATE_USER_HABITS',
	DELETE_USER_HABITS: 'DELETE_USER_HABITS',

	//Subscription
	ADD_SUBSCRIPTION: 'ADD_SUBSCRIPTION',
	GET_SUBSCRIPTION: 'GET_SUBSCRIPTION',
	ADD_USER_SUBSCRIPTION: 'ADD_USER_SUBSCRIPTION',
	GET_USER_SUBSCRIPTION: 'GET_USER_SUBSCRIPTION',
	SET_USER_ALLOWED_CONTENT: 'SET_USER_ALLOWED_CONTENT',
	SET_PREMIUM_USER_FLAG: 'SET_PREMIUM_USER_FLAG',
	SET_PREMIUM_USER_STATUS_FLAG: 'SET_PREMIUM_USER_STATUS_FLAG',

	//poems
	GET_POEMS: 'GET_POEMS',
	GET_USER_POEMS: 'GET_USER_POEMS',
	SET_OPENED_POEM: 'SET_OPENED_POEM',
	SET_SAVED_POEM: 'SET_SAVED_POEM',
	GET_USER_LIKED_POEMS: 'GET_USER_LIKED_POEMS',
	GET_TRENDING_POEMS: 'GET_TRENDING_POEMS',
	GET_WALL_OF_FAME_POEMS: 'GET_WALL_OF_FAME_POEMS',
	GET_USER_PUBLISHED_POEMS: 'GET_USER_PUBLISHED_POEMS',
	GET_USER_DRAFT_POEMS: 'GET_USER_DRAFT_POEMS',
	SET_USER_DRAFT_POEM_EDIT: 'SET_USER_DRAFT_POEM_EDIT',
	SET_SHOW_POEM: 'SET_SHOW_POEM',
	SET_SHOW_POEM_INDEX: 'SET_SHOW_POEM_INDEX',
	GET_GLOBAL_POEMS: 'GET_GLOBAL_POEMS',

	//essay
	GET_ESSAYS: 'GET_ESSAYS',
	GET_USER_ESSAYS: 'GET_USER_ESSAYS',
	SET_OPENED_ESSAY: 'SET_OPENED_ESSAY',
	SET_SAVED_ESSAY: 'SET_SAVED_ESSAY',
	GET_USER_LIKED_ESSAYS: 'GET_USER_LIKED_ESSAYS',
	GET_TRENDING_ESSAYS: 'GET_TRENDING_ESSAYS',
	GET_WALL_OF_FAME_ESSAYS: 'GET_WALL_OF_FAME_ESSAYS',
	GET_USER_PUBLISHED_ESSAYS: 'GET_USER_PUBLISHED_ESSAYS',
	GET_USER_DRAFT_ESSAYS: 'GET_USER_DRAFT_ESSAYS',
	SET_USER_DRAFT_ESSAY_EDIT: 'SET_USER_DRAFT_ESSAY_EDIT',
	SET_SHOW_ESSAY: 'SET_SHOW_ESSAY',
	SET_SHOW_ESSAY_INDEX: 'SET_SHOW_ESSAY_INDEX',


	//reviewer
	GET_POEMS_FOR_REVIEW: 'GET_POEMS_FOR_REVIEW',
	GET_ESSAYS_FOR_REVIEW: 'GET_ESSAYS_FOR_REVIEW',
	GET_BOOKS_FOR_REVIEW: 'GET_POEMS_FOR_REVIEW',
	SET_REVIEWER: 'SET_REVIEWER',
	GET_REVIEW_REQUEST: 'GET_REVIEW_REQUEST',

	//library
	SET_FILTER: 'SET_FILTER',

	//notifications
	SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
	GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',

	//B2B 

	//user
	SET_INSTITUTE: 'SET_INSTITUTE',
	SET_USER: 'SET_USER',
	GET_USERS: 'GET_USERS',
	GET_CO_ORDINATORS: 'GET_CO_ORDINATORS',
	SET_TENANT_ID: 'SET_TENANT_ID',
	SET_ACTIVE_PAGE_INDEX: 'SET_ACTIVE_PAGE_INDEX',
	SET_USER_AVATAR: 'SET_USER_AVATAR',
	SET_IS_INSTITUTE: 'SET_IS_INSTITUTE',

	//roles
	GET_ROLES: 'GET_ROLES',

	//site-insights
	SET_STUDENTS_COUNT: 'SET_STUDENTS_COUNT',
	SET_EMPLOYEES_COUNT: 'SET_EMPLOYEES_COUNT',
	SET_BLOGS_COUNT: 'SET_BLOGS_COUNT',

	//complaints
	GET_USER_COMPLAINTS: 'GET_USER_COMPLAINTS',
	GET_COMPLAINTS: 'GET_COMPLAINTS',

	//announcements
	GET_ANNOUNCEMENTS: 'GET_ANNOUNCEMENTS',

	//Permissions
	GET_DEFAULT_PERMISSIONS: 'GET_DEFAULT_PERMISSIONS',
	GET_INSTITUTE_PERMISSIONS: 'GET_INSTITUTE_PERMISSIONS',
	SET_USER_PERMISSIONS: 'SET_USER_PERMISSIONS',

};

// Resets the currently visible error message.
export const triggerReducer = (type, extra = {}) => ({
	type: type,
	...extra
})
