import React, { useState } from "react";
import DiaryStyles from "./DiaryStyles";
import boyImg from "../../assets/images/DiaryImages/shutterstock_2121447629.png";
import {
  Grid,
  Box,
  Typography,
  Button,
} from "@mui/material";
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
} from "date-fns";
import DiaryMoodSection from "./DiaryMoodSection";
import { useHistory } from "react-router-dom";

function DiaryDate(props) {
  const classes = DiaryStyles();
  const history = useHistory();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  // current day and dates
  const dateFormat = "dd";
  const MonthFormat = "MMMM";
  const WeekName = "cccc";

  return (
    <>
      {/* <div className={classes.mainCardUp}> */}
      <Grid container>
        <Grid
          item
          xs={12}
          md={12}
          sm={12}
          lg={12}
          // backgroundColor="black"
          width="100%"
          display="flex"
          justify-content="space-between"
        >
          <div className={classes.mainCardUp}>
            <Grid item xs={12} md={12} sm={12} lg={7.5}>
              <Box
                className={classes.cardRes}
              // sx={{
              //   width: "100%",
              //   height: "65vh",
              //   borderRadius: "2rem",
              //   // marginRight: "2rem",
              //   // marginBottom: "2rem",
              //   backgroundColor: "#77D4E3",
              //   fontFamily: "Museo700",
              //   padding: "2rem",
              //   // backgroundColor: "red",
              // }}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  fontFamily="Museo700"
                  color="#0B2F64"
                >
                  Today | {format(new Date(), WeekName)}
                </Typography>
                <Typography
                  style={{
                    fontSize: "80px",
                    fontWeight: "bold",
                    color: "#0B2F64",
                  }}
                >
                  {/* {format(addDays(startDate), )} */}
                  {format(new Date(), dateFormat)}
                </Typography>
                <Typography
                  color="#0B2F64"
                  style={{
                    fontSize: "30px",
                    fontWeight: "bold",
                    marginBottom: "2rem",
                  }}
                >
                  {format(currentMonth, MonthFormat)}
                  {/* February */}
                </Typography>
                <Button style={{ fontSize: '14px', color: "#0B2F64", fontFamily: "Museo700", background: 'rgb(255, 193, 7)', borderRadius: '19px', padding: '14px' }}
                  onClick={() => history.push("/diary-editor")}>
                  Start writing Here !
                </Button>
                <Grid container sx={{ position: "relative" }}>
                  <img className={classes.cardResImg} src={boyImg} alt="img" />
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={4}>
              <DiaryMoodSection
                setCalendarColor={props.setCalendarColor}
                setUpdateEmojiImg={props.setUpdateEmojiImg}
                setMoodValue={props.setMoodValue}
                moodValue={props.moodValue}
                setFeelings={props.setFeelings}
              />
            </Grid>
          </div>
        </Grid>
      </Grid>

      {/* </div> */}
    </>
  );
}

export default DiaryDate;