import React, { useDebugValue, useEffect, useRef, useState } from "react";
import {
  Grid,
  Container,
  Box,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import InfoIcon from '@mui/icons-material/Info';
import DiaryStyles from "./DiaryStyles";
import Footer from "../Footer";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import add2 from "../../assets/images/dashboard/2.png";
import add1 from "../../assets/images/dashboard/1.png";

import {
  getGoals,
  saveGoals,
  updateGoals,
  openedGoal,
  deleteGoal,
} from "../../store/actions/goals";
import AppCreate from "../Blog/blogSections/AppCreate";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import moment from "moment";
import "./Styles.css";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

import {
  addDiaryPage,
  getDiaryPage,
  getDiaryDates,
  clearDiaryDates
} from "../../store/actions/diary";


import {
  format,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
} from "date-fns";
import DiaryDate from "./DiaryDate";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NewAppToDo from "../Dashboard/sections/NewAppToDo";
import NewAppGoalComp from "../Dashboard/sections/NewAppGoalComp";
import Happy from "../../assets/images/Happy.png";
import Neutral from "../../assets/images/Neutral.png";
import Sad from "../../assets/images/Sad.png";
import VeryHappy from "../../assets/images/VeryHappy.png";
import VerySad from "../../assets/images/VerySad.png";
import CreateFabButton from "../Dashboard/CreateFabButton";
import DiaryHabit from "./DiaryHabit";

import Navbar from "../Navbar";
import Main from "../Main";

const styles = {
  backgroundColor: '#fff',
  backgroundImage: 'none',
  paddingBottom: 50
};

const TASKS = [];

TaskItem.propTypes = {
  task: PropTypes.string,
  checked: PropTypes.bool,
  formik: PropTypes.object,
};

function TaskItem({
  goalData,
  task,
  checked,
  formik,
  goalId,
  date,
  setDeleteIds,
  deleteIds,
  ...other
}) {
  const { getFieldProps } = formik;
  const [text, setText] = useState(task);
  const [goalID, setGoalID] = useState(goalId);
  const auth = useSelector((state) => state.firebase.auth);
  const openedGoalState = useSelector((state) => state.goals.openedGoal);


  const [value, setValue] = useState(new Date());
  const classes = DiaryStyles();
  const dispatch = useDispatch();

  const handleGoalChange = (e) => {
    let goal = {
      goal: e.target.value,
    };
    if (!goalID) {
      goal = {
        ...goal,
        date: new Date(),
        status: 0,
        userId: auth.uid,
      };
    }
    dispatch(openedGoal(goal));
    setText(e.target.value);
  };

  useEffect(() => {
    let goal = {
      status: checked ? 1 : 0,
    };
    if (checked && goalId) {
      if (deleteIds && deleteIds.length > 0) {
        let goals = [...deleteIds];
        goals.push(goalId);
        setDeleteIds(goals);
      } else {
        let goals = [];
        goals.push(goalId);
        setDeleteIds(goals);
      }
    }
  }, [checked]);

  const handleOnBlur = (e) => {
    if (goalID) {
      let goal = {
        goal: e.target.value,
      };
      dispatch(updateGoals(goal, goalId));
    } else {
      let goal = {
        goal: e.target.value,
        date: new Date(),
        status: 0,
        userId: auth.uid,
      };
      dispatch(saveGoals(goal));
    }
  };

  return (
    <Stack
      width="100%"
      direction="row"
      justifyContent="space-between"
      sx={{ py: 0.75 }}
    >
      <FormControlLabel
        control={
          <Checkbox
            icon={<CircleIcon />}
            checkedIcon={<CheckCircleIcon />}
            style={{ borderRadius: "50%", color: "white" }}
            {...getFieldProps("checked")}
            value={task}
            checked={checked}
            {...other}
          />
        }
        label={
          <input
            type="text"
            value={text}
            name="text"
            onChange={handleGoalChange}
            onBlur={handleOnBlur}
            className={classes.goalsStyling}
          />
        }
      />

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          minDate={new Date()}
          className={classes.datePickerStyle}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} className={classes.datePickerStyle} />
          )}
        />
      </LocalizationProvider>
    </Stack>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  [theme.breakpoints.down("sm")]: {},
}));

const imageArray = [VerySad, Sad, Neutral, Happy, VeryHappy];
const feelingsArray = ['very_sad', 'sad', 'neutral', 'happy', 'very_happy']
const moodColors = ['#E88492', '#FA8072', '#FFA07A', '#50C878', '#69B39F']

function Diary(props) {
  const classes = DiaryStyles();
  const auth = useSelector((state) => state.firebase.auth);
  const [interests, setPreferences] = useState([]);
  const [status, setStatus] = useState(new Array(30));
  const [statusRead, setStatusRead] = useState(new Array(30));
  const [statusDrink, setStatusDrink] = useState(new Array(30));
  const [deleteIds, setDeleteIds] = useState([]);
  const [goalsList, setGoalsList] = useState([]);
  const [calendarColor, setCalendarColor] = useState("#E88492");
  const [updateEmojiImg, setUpdateEmojiImg] = useState(3);
  const [moodValue, setMoodValue] = useState(2)
  const [feelings, setFeelings] = useState('happy')
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch();
  const { goals } = useSelector((state) => state.goals);
  const openedDiary = useSelector((state) => state.diary.openedDiary);
  const { diaryDates } = useSelector((state) => state.diary)

  useEffect(() => {
    dispatch(getGoals());
    let payload = {
      userid: auth.uid,
      on: moment(new Date()).format('YYYY/MM/DD')
    }
    dispatch(getDiaryPage(payload))
    // dispatch(getJournalPage(payload))
  }, []);

  useEffect(() => {
    setGoalsList(goals);
  }, [goals, goals.length])

  useEffect(() => {
    setUpdateEmojiImg(moodValue)
    setCalendarColor(moodColors[moodValue] || '#B22222')
    setFeelings(feelingsArray[moodValue])

    if (loading) {
      let date = new Date()
      let on = moment(date).format('YYYY/MM/DD')
      let userid = auth.uid
      let { feelings } = openedDiary

      const diary = {
        ...openedDiary,
        userid,
        on,
        feelings: feelingsArray[moodValue],
        color: calendarColor,
        feelingChange: true
      };
      if (feelings !== feelingsArray[moodValue])
        dispatch(addDiaryPage(diary));
    }
    setLoading(true)
  }, [moodValue])

  useEffect(() => {
    const { feelings } = openedDiary
    if (feelings) {
      let index = feelingsArray.findIndex(f => f === feelings)
      if (index > -1) {
        setUpdateEmojiImg(index)
        setCalendarColor(moodColors[index] || '#B22222')
        setFeelings(feelings)
        setMoodValue(index)
      }
    }
  }, [openedDiary])

  useEffect(() => {
    let newArrayData = new Array(30);
    newArrayData.fill(false);
    setStatus(newArrayData);
    setStatusRead(newArrayData);
    setStatusDrink(newArrayData);
  }, []);

  const changecolor = (index) => {
    let newData = [...status];
    newData[index] = !newData[index];
    setStatus(newData);
  };

  const changecolorRead = (index) => {
    let newData = [...statusRead];
    newData[index] = !newData[index];
    setStatusRead(newData);
    // setStatus(newData);
  };
  const changecolorBook = (index) => {
    let newData = [...statusDrink];
    newData[index] = !newData[index];
    setStatusDrink(newData);
    // setStatus(newData);
  };

  const formik = useFormik({
    initialValues: {
      checked: [TASKS[2]],
    },
    onSubmit: (values) => {
      //
    },
  });

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    dispatch(clearDiaryDates())
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    let payload = {
      startDate,
      endDate
    }
    dispatch(getDiaryDates(payload))
  }, [currentWeek])

  const changeWeekHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === "next") {
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
  };

  ///////////////////////Calender Dates
  const onDateClickHandle = (day, dayStr) => {
    setSelectedDate(day);
  };

  const renderHeader = () => {
    const dateFormat = "MMMM";
    const currentDateFormat = "dd";

    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    let day = startDate;
    let dayEnd = endDate;

    return (
      <div>
        <Typography className={classes.dateOfmonthStyle}
          // gutterBottom
          // variant="h6"
          // textAlign="center"
          component="div"
          fontFamily="Museo700"
          color="#0B2F64"
        >
          {format(currentMonth, dateFormat)} {format(day, currentDateFormat)}-
          {format(dayEnd, currentDateFormat)}
          <FaChevronLeft onClick={() => changeWeekHandle("prev")} />
          <FaChevronRight onClick={() => changeWeekHandle("next")} />
        </Typography>
      </div>
    );
  };

  //////////////////////render day
  const renderDays = () => {
    const dateFormat = "EEE";
    const days = [];
    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });

    for (let i = 0; i < 7; i++) {
      let day = addDays(startDate, i);

      days.push(
        <div className="col col-center" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }
    return <div className="days row">{days}</div>;
  };

  const getIndex = (date) => {
    let index = diaryDates.findIndex(dDate =>
      moment.unix(dDate.on.seconds).format('YYYYMMDD') == moment(date).format('YYYYMMDD')
    )
    if (index > -1) {
      let { feelings } = diaryDates[index]
      if (feelings) {
        let index = feelingsArray.findIndex(f => f === feelings)
        if (index > -1) {
          return index;
        }
      }
    }
    return -1
  }
  ///////////////////calender dates
  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    let todayDate = moment().format('YYYYMMDD');
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        let dateCurrent = moment(day).format('YYYYMMDD');
        const cloneDay = day;

        let add = todayDate === dateCurrent;
        let imageIndex = getIndex(day)
        days.push(
          <div
            className={`col cell ${isSameDay(day, new Date())
              ? "today"
              : isSameDay(day, selectedDate)
                ? "selected"
                : ""
              }`}
            key={day}
            onClick={() => {
              const dayStr = format(cloneDay, "ccc dd MMM yy");
              onDateClickHandle(cloneDay, dayStr);
            }}
          >
            &nbsp; &nbsp;
            <span
              className={classes.radioCustomBtn}
              style={{
                backgroundColor: add ? "white" : "none",
                borderRadius: add ? "40px" : "none",
              }}
            >
              {" " + formattedDate}
              <br />
              {
                add ?
                  <img
                    src={imageArray[updateEmojiImg]}
                    alt="img"
                    style={{ width: "30px", height: "30px" }}
                  /> :
                  imageIndex > -1 ?
                    <img
                      src={imageArray[imageIndex]}
                      alt="img"
                      style={{ width: "30px", height: "30px" }}
                    /> : null
              }


            </span>
            {/* <span className="bg">{formattedDate}</span> */}
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };

  const [DaysInMonth, setDaysInMonth] = useState([]);
  useEffect(() => {
    showDate();
  }, []);

  const showDate = () => {
    const currentDate = moment();
    const daysIsmonths = currentDate.daysInMonth();
    const arr = [];
    for (let i = 0; i < daysIsmonths; i++) {
      arr.push(i);
    }
    setDaysInMonth(arr);
  };

  const handleDeleteGoal = () => {
    if (deleteIds && deleteIds.length > 0) {
      //
      dispatch(deleteGoal(deleteIds));
      setDeleteIds([]);
    }
  };
  return (
    <Main styles={styles}>
      <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid item xs={12} md={12} style={{ minHeight: '80px' }}>
          <Navbar />
        </Grid>
        <Container maxWidth="xl">
          <Grid container spacing={2} className={classes.mainContainerDiaryStyle}>
            <Grid item xs={12} md={10} lg={10}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  margin="auto"
                  sx={{ backgroundColor: "white", height: "15%" }}
                >
                  <AppCreate />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.main}
                  sx={{
                    display: "flex",
                    marginTop: "1.5rem",
                  }}
                >
                  <Grid container className={classes.mainParticlesLeft}>
                    <Grid item xs={12} sm={12} md={12} lg={8.5}>
                      <Grid item xs={12} sm={12} md={12}>
                        <DiaryDate
                          setCalendarColor={setCalendarColor}
                          setUpdateEmojiImg={setUpdateEmojiImg}
                          setMoodValue={setMoodValue}
                          moodValue={moodValue}
                          setFeelings={setFeelings}
                        />
                        {/* <DiaryMoodSection /> */}
                      </Grid>
                      {/* <Grid item xs={12} sm={12} md={12} lg={8}> */}

                      <Grid item xs={12} sm={12} md={12}>
                        <div
                          className={classes.mainCardDown}
                          style={{ backgroundColor: calendarColor }}
                        >

                          <Box
                            sx={{
                              display: "flex",
                              height: "25%",
                              padding: "2rem",

                              // backgroundColor: "green",
                              justifyContent: "space-between",
                            }}
                          >
                            {renderHeader()}

                            <Box
                              gutterBottom
                              // textAlign="center"
                              // component="div"
                              color="#0B2F64"
                            >
                              <ListItemButton
                                style={{
                                  backgroundColor: "#ffd3df",
                                  borderRadius: "2rem",
                                  height: "33px",
                                }}
                                onClick={handleClick}
                              >
                                <ListItemText
                                  fontFamily="Museo700"
                                  primary="Weekly"
                                />
                                {open ? <ExpandLess /> : <ExpandMore />}
                              </ListItemButton>
                            </Box>
                          </Box>
                          <hr style={{ color: "white" }}></hr>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              // backgroundColor: "green",
                              fontFamily: "Museo700",
                              height: "50%",
                              justifyContent: "space-around",
                              padding: "10px",
                              color: "#0B2F64",
                            }}
                          >
                            {/* ///day present monday, Sunday ,etc */}
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                            // style={{ backgroundColor: "red" }}
                            >
                              {renderDays()}
                            </Grid>

                            {/* ///present date */}

                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                            // style={{ backgroundColor: "red" }}
                            >
                              {renderCells()}
                            </Grid>
                          </Box>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={3}>
                      <NewAppToDo />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={12} sm={12} md={12} lg={5.8}>
                    <div className={classes.mainContentDownLeft}>
                      <Box
                        style={{
                          display: "flex",
                          position: "relative",
                          width: "260px",
                          textAlign: "center",
                          justifyContent: "center",
                          margin: "auto",
                          // marginRight: "20px",
                          padding: "10px",
                        }}
                      >
                        <Tooltip title="Having difficulty sticking to a routine?
                            Add a habit and let us help you keep a daily track of your habit!
                            Word limit-25 characters.
                            ">
                          <IconButton style={{ color: '#23348f', marginTop: '-0.5rem' }}>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>

                        <Typography
                          gutterBottom
                          variant="h5"
                          textAlign="center"
                          component="div"
                          fontFamily="Museo700"
                          color="#0B2F64"
                        >
                          Habit Tracker
                          {/* <IoMdAddCircle
                            style={{
                              position: "absolute",
                              right: "-14px",
                            }}
                          /> */}
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          // display: "flex",
                          textAlign: "center",
                          marginTop: "3rem !important",
                          justifyContent: "center",
                        }}
                      >
                        <DiaryHabit />
                        {/* <Button
                          className={classes.comingsoonBtnStyle}
                          style={{
                            width: "25vw",
                            borderRadius: "5rem",
                            fontSize: "1.2rem",
                            fontWeight: 700,
                            marginTop: "3rem !important",
                            background: "white",
                            color: "#001f58",
                            padding: "15px 25px 15px 20px",
                          }}
                        >
                          Coming Soon
                        </Button> */}

                        {/* <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            gutterBottom
                            variant="p"
                            color="#0B2F64"
                            component="div"
                          >
                            Drink 2 liter of water
                          </Typography>
                          <RiDeleteBinLine color="#0B2F64" />
                        </div>  */}
                        {/* 
                   <div className={classes.DownLeftMini}>
                         
                       
                          {interestsListArray.map((interest, index) => (
                            <Button
                              className={classes.btnStyles}
                              style={{
                                background:
                                  parseInt(moment().date()) - 1 < index
                                    ? "gray"
                                    : status[index]
                                      ? "white"
                                      : color,
                                color: status[index] ? "#a093c7" : textColor,
                                margin: "2px",
                              }}
                              disabled={parseInt(moment().date()) - 1 < index}
                              onClick={() =>
                                parseInt(moment().date()) - 1 < index
                                  ? null
                                  : changecolor(index)
                              }

                            >
                              {" "}

                              {interest}
                            </Button>

                          ))}
                        </div> */}
                      </Box>


                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={5.8}>
                    <div className={classes.mainContentDownRight}>
                      <Box
                        style={{
                          display: "flex",
                          // position: "absolute",
                          width: "220px",
                          textAlign: "center",
                          justifyContent: "center",
                          margin: "auto",
                          // marginRight: "20px",
                        }}
                      >
                        {/* <Grid
                        lg={12}
                        container
                        sx={{ padding: "1rem", bacgroundColor: "red" }}
                      > */}
                        <Tooltip title="Type in your goal, press enter/+ and use our calendar to set a date to achieve it.
                            ">
                          <IconButton style={{ color: '#23348f', marginTop: "-0.5rem" }}>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>

                        <Typography
                          gutterBottom
                          variant="h5"
                          textAlign="center"
                          component="div"
                          fontFamily="Museo700"
                          color="#0B2F64"
                        >
                          Goals
                        </Typography>
                        {/* </Grid> */}

                      </Box>
                      <Box sx={{ px: 2, py: 1 }}>
                        <Box
                          sx={{
                            // backgroundColor: "red",
                            display: "flex",
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              py: 1,
                              color: "#0B2F64",
                            }}
                          >
                            {/* <FormikProvider
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              value={formik}
                            > */}
                            {/* <Form
                                autoComplete="off"
                                noValidate
                                onSubmit={handleSubmit}
                              > */}
                            {/* {goalsList.map((task, index) => (
                                  <AppToDoComp 
                                  />
                                  // <TaskItem
                                  //   key={index}
                                  //   task={task.goal}
                                  //   formik={formik}
                                  //   checked={values.checked.includes(task.goal)}
                                  //   goalId={task.id}
                                  //   date={task.date}
                                  //   deleteIds={deleteIds}
                                  //   setDeleteIds={setDeleteIds}
                                  // />
                                ))} */}
                            <NewAppGoalComp />

                            {/* {generateFields()}
                                <IoMdAddCircle
                                  style={{
                                    fontSize: "2rem",
                                    marginRight: "auto",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleButtonClick}
                                /> */}
                            {/* </Form> */}
                            {/* </FormikProvider> */}
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  </Grid>
                </Grid>

                {/* </Box> */}
                {/* </Grid> */}
                {/* </Grid> */}
              </Grid>
            </Grid>

            <Grid item xs={12} md={4} lg={2} xl={2}>
              <Grid
                container
                direction={"row"}
                spacing={2}
                className={classes.progStyle}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <img
                    src={add1}
                    alt="Train"
                    className={classes.DiaryAddImg}
                    width="100%"
                  />
                  {/* <DiaryProgress /> */}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box>
                    <img
                      src={add2}
                      alt="Train"
                      width="100%"
                      className={classes.DiaryAddImg}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box className={classes.createFabStyles3} >
            <CreateFabButton />
          </Box>
        </Container>
      </Grid>
    </Main>
  );
}

export default Diary;
