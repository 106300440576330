import React from "react";
import { useHistory } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';
import blogiconhover from "../../../assets/images/dashboard/blogiconhover.svg";
import libraryiconhover from "../../../assets/images/dashboard/libraryiconhover.svg";
import cardiconhover from "../../../assets/images/dashboard/cardiconhover.svg";
import BlogIconSrc from "../../../assets/images/dashboard/blogicon.png";
import DiaryIconSrc from "../../../assets/images/dashboard/diary.png";
import CardIconSrc from "../../../assets/images/dashboard/card.png";

import poemiconhover from "../../../assets/images/dashboard/poemiconhover.svg";
import essayiconhover from "../../../assets/images/dashboard/essayiconhover.svg";
import PoemIconSrc from "../../../assets/images/dashboard/poem.png";
import EssayIconSrc from "../../../assets/images/dashboard/essay.png";

import DashboardStyles from "../DashboardStyles";
// components from local src/components
import IconCircle from '../IconCircle';

export default function StartComp() {
  const history = useHistory();
  const classes = DashboardStyles();

  const redirectTo = (path) => {
    history.push(path)
  }
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={0}
      maxWidth="100%"
      className={classes.letboxStyle}
    // style={{
    //   color: "white",
    //   background: "linear-gradient(0deg, #6acfff, #2d00b7)",
    //   borderRadius: "1rem",
    //   padding: "1rem",
    // }}
    >
      <h3 style={{ color: "white", fontSize: "2rem", fontFamily: 'Museo300', fontWeight: '500 !important' }}>
        Let's Start
      </h3>
      <Grid
        container
        spacing={0}
        justifyContent="space-evenly"
        alignItems="center"
        padding={2}
        // maxWidth="40%"
        className={classes.LetStartStyle}
      >
        <Grid
          item
          minWidth={0.125}
          style={{ cursor: "pointer" }}
          onClick={() => redirectTo("/blog")}
        >
          <IconCircle
            HoverCompSrc={blogiconhover}
            IconCompSrc={BlogIconSrc}
            // IconCompSrc={BlogIconSrc}
            DispTitle="Blog"
          />
        </Grid>
        <Grid
          item
          minWidth={0.125}
          onClick={() => redirectTo("/diary")}
          style={{ cursor: "pointer" }}
        >
          {/* <IconCircle
            IconCompSrc={DiaryIconSrc}
            HoverCompSrc={blogiconhover}
            DispTitle="Diary"
          /> */}
          <IconCircle
            IconCompSrc={DiaryIconSrc}
            HoverCompSrc={libraryiconhover}
            DispTitle="Diary"
          />
        </Grid>
        <Grid
          item
          minWidth={0.125}
          onClick={() => redirectTo("/postcard")}
          style={{ cursor: "pointer" }}
        >
          <IconCircle
            IconCompSrc={CardIconSrc}
            HoverCompSrc={cardiconhover}
            DispTitle="Card"
          />
        </Grid>
        <Grid
          item
          minWidth={0.125}
          onClick={() => redirectTo("/poem")}
          style={{ cursor: "pointer" }}
        >
          <IconCircle
            IconCompSrc={PoemIconSrc}
            HoverCompSrc={poemiconhover}
            DispTitle="Poem"
          />
        </Grid>

        <Grid
          item
          minWidth={0.125}
          onClick={() => redirectTo("/essay")}
          style={{ cursor: "pointer" }}
        >
          <IconCircle
            IconCompSrc={EssayIconSrc}
            HoverCompSrc={essayiconhover}
            DispTitle="Story"
          />
        </Grid>
        {/* <Grid item minWidth={0.125} style={{ cursor: "pointer" }}>
          <IconCircle
            HoverCompSrc={bookiconhover}
            IconCompSrc={BookIconSrc}
            DispTitle="Book"
          />
        </Grid>
        <Grid item minWidth={0.125}>
          <IconCircle
            IconCompSrc={PoemIconSrc}
            HoverCompSrc={poemiconhover}
            DispTitle="Poem"
          />
        </Grid>
        <Grid item minWidth={0.125}>
          <IconCircle
            IconCompSrc={EssayIconSrc}
            HoverCompSrc={essayiconhover}
            DispTitle="Story"
          />
        </Grid>
        <Grid item minWidth={0.125}>
          <IconCircle
            IconCompSrc={ComicIconSrc}
            HoverCompSrc={comiciconhover}
            DispTitle="Comic"
          />
        </Grid>
        <Grid item minWidth={0.125}>
          <IconCircle
            IconCompSrc={SBookIconSrc}
            HoverCompSrc={scrapbookiconhover}
            DispTitle="ScrapBook"
          />
        </Grid> */}
      </Grid>
    </Stack>
  );
}
